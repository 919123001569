var feebackQuestion = $(".js-feedback-question");
var pageIsUsefulButton = $(".js-page-is-useful");
var pageIsNotUsefulButton = $(".js-page-is-not-useful");

// Hide the question and the other button when a feedback button is clicked. As this function works faster than the time it takes to process the form submission, also hide the button as soon as it is clicked so that we don't see it on it's own after the other elements are hidden.
pageIsUsefulButton.on("click", function() {
    $(this).addClass("!hidden");
    pageIsNotUsefulButton.addClass("!hidden");
    feebackQuestion.addClass("hidden");
});
pageIsNotUsefulButton.on("click", function() {
    $(this).addClass("!hidden");
    pageIsUsefulButton.addClass("!hidden");
    feebackQuestion.addClass("hidden");
});